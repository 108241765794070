import { InputSelect } from '@creditas/form-elements/dist/InputSelect'
import { styled } from '@creditas/stylitas'
import { Typography } from '@creditas/typography'

const HelperText = styled(Typography)`
  margin: -12px 0 8px 0;
`

const Wrapper = styled.div`
  margin-bottom: 16px;
  width: 100%;
`

const SelectField = styled(InputSelect)`
  width: 100%;
  margin-bottom: -16px;

  select {
    width: 100%;
  }
`

export { Wrapper, HelperText, SelectField }
