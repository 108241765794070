import React from 'react'
import { array, bool, func, oneOf, string } from 'prop-types'

import { HelperText, SelectField, Wrapper } from './Select.style'

const propTypes = {
  'data-testid': string,
  disabled: bool,
  helperText: string,
  id: string.isRequired,
  label: string,
  name: string,
  onBlur: func,
  onChange: func,
  onFocus: func,
  options: array,
  required: bool,
  variant: oneOf(['success', 'warning', 'danger', 'default']),
}

const defaultProps = {
  'data-testid': 'checkbox',
}

const Select = React.forwardRef((props, ref) => {
  const {
    'data-testid': dataTestid,
    disabled,
    helperText,
    id,
    label,
    name,
    onBlur,
    onChange,
    onFocus,
    options,
    required,
    variant: statusVariant,
  } = props

  const selectOptions =
    options?.map(({ label: optionLabel, value }) => ({
      text: optionLabel,
      value,
    })) || []

  return (
    selectOptions?.length && (
      <Wrapper as="fieldset" data-testid={dataTestid}>
        <SelectField
          name={name}
          label={label}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          options={selectOptions}
          id={id}
          variant={statusVariant}
          ref={ref}
          disabled={disabled}
          required={required}
        />

        {helperText && (
          <HelperText variant="supportForm" color={statusVariant}>
            {helperText}
          </HelperText>
        )}
      </Wrapper>
    )
  )
})

Select.displayName = 'Select'
Select.propTypes = propTypes
Select.defaultProps = defaultProps

export default Select
